import * as React from 'react';
import { useForm } from '@mantine/form';
import { useMutation } from '@apollo/client';
import { Alert, Button, Flex, Loader, TextInput } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import * as GQLQueries from '../lib/queries';
const ResetPasswordForm = () => {
  const [errorDisplay, setErrorDisplay] = React.useState<string>('');
  const [successDisplay, setSuccessDisplay] = React.useState<string>('');
  const form = useForm({
    initialValues: {
      email: ''
    },
    validate: {
      email: value => !value ? 'Email is required' : !/^\S+@\S+$/.test(value) ? 'Invalid email' : null
    },
    validateInputOnChange: true
  });
  const [resetUserPassword, {
    error,
    loading: isLoading
  }] = useMutation(GQLQueries.Mutations.ResetUserPassword);
  const handleSubmit = async (values: {
    email: string;
  }) => {
    setErrorDisplay('');
    setSuccessDisplay('');
    const response = await resetUserPassword({
      variables: values
    });
    const success = response?.data?.resetUserPassword;
    if (success) {
      setSuccessDisplay('If we have an account matching that email, you will receive a reset link shortly.');
    } else {
      setErrorDisplay("request wasn't successful");
    }
  };
  const displayError = error?.message || errorDisplay || undefined;
  return <form onSubmit={form.onSubmit(values => handleSubmit(values))} data-sentry-component="ResetPasswordForm" data-sentry-source-file="ResetPasswordForm.tsx">
			<Flex gap="md" justify="flex-start" direction="column" pt="md" data-sentry-element="Flex" data-sentry-source-file="ResetPasswordForm.tsx">
				<TextInput label="Email" type="email" {...form.getInputProps('email')} placeholder={'the email address you signed up with'} data-sentry-element="TextInput" data-sentry-source-file="ResetPasswordForm.tsx" />
				<Button type="submit" mt="lg" disabled={isLoading || !form.isValid()} data-sentry-element="Button" data-sentry-source-file="ResetPasswordForm.tsx">
					{isLoading ? <>
							Requesting reset link&nbsp;&nbsp;&nbsp;
							<Loader size="sm" variant="dots" color="gray" />
						</> : 'Request reset link'}
				</Button>
				{displayError && <Alert icon={<AlertCircle size="1rem" />} title="Something went wrong" color="red">
						{displayError}
					</Alert>}

				{successDisplay && <Alert icon={<AlertCircle size="1rem" />} title="Request received" color="green">
						{successDisplay}
					</Alert>}
			</Flex>
		</form>;
};
export default ResetPasswordForm;