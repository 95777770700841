import * as Types from '../declarations'

export const isSet = (
	value: string | number | boolean | undefined,
	propName: string,
) => !value && `${propName} is required`

export const isNumber = (value: number | undefined, propName: string) =>
	value !== undefined && isNaN(value) ? `${propName} must be number` : null

export const isBoolean = (value: string | number | boolean, propName: string) =>
	typeof value !== 'boolean' ? `${propName} must be boolean` : null

export const isNumberAndNonZero = (value: number, propName: string) => {
	const noValue = !value
	const isNan = isNaN(value)
	const isZero = value === 0
	const isInvalid = noValue || isNan || isZero

	return isInvalid ? `${propName} must be a number and not zero` : null
}

export const isNumberAboveMinusOne = (value: number, propName: string) =>
	!value || isNaN(value) || value < 0
		? `${propName} must be a number and above minus one`
		: null

export const areNumbersOnSimpleScale = (values: string[], propName: string) =>
	values.filter(
		(value: string | number) =>
			!(!value || isNaN(+value) || value < 1 || value > 7),
	).length < 1
		? `${propName} must contain valid values.`
		: null

/*
	export const isNumberOrHypenSeparateredNumberRange = (
		value: any,
		propName: string,
	) => {
		let invalid = true
	
		if (!!value) {
			const seperated = value.split('-')
			if (seperated.length !== 1 || seperated.length !== 2) {
				const containsInvalid = ((array: (number | string)[]): boolean => {
					for (const value of array) {
						if (typeof value !== 'number' || isNaN(value) || value === 0) {
							return true // Returns true if a non-number or zero is found
						}
					}
					return false // Returns false if all values are numbers and non-zero
				})(seperated)
	
				if (!containsInvalid) {
					invalid = false
				}
			}
		}
	
		console.log(`evaluating ${propName}`, invalid)
	
		return invalid
			? `${propName} must be either a number, or two numbers separated by a hyphen (-), and the number(s) can't be zero`
			: null
	}
	*/

export const isNumberArrayOneOrTwoItems = (
	value: number[],
	propName: string,
) => {
	const containsRightNumberOfItems = value.length === 1 || value.length === 2

	const containsInvalid = ((array: (number | string)[]): boolean => {
		for (const value of array) {
			if (typeof value !== 'number' || isNaN(value) || value === 0) {
				return true // Returns true if a non-number or zero is found
			}
		}
		return false // Returns false if all values are numbers and non-zero
	})(value)

	const invalid = !containsRightNumberOfItems || containsInvalid

	return invalid
		? `${propName} must be either a number, or two numbers separated by a hyphen (-), and the number(s) can't be zero`
		: null
}

export const isNumberArrayMinOneItem = (value: number[], propName: string) => {
	const containsRightNumberOfItems = value.length > 0

	const containsInvalid = ((array: (number | string)[]): boolean => {
		for (const value of array) {
			if (typeof value !== 'number' || isNaN(value) || value === 0) {
				return true // Returns true if a non-number or zero is found
			}
		}
		return false // Returns false if all values are numbers and non-zero
	})(value)

	const invalid = !containsRightNumberOfItems || containsInvalid

	return invalid ? `${propName} must contain at least one number (month)` : null
}

export const isArrayAtLeastOneItem = (value: number[], propName: string) => {
	const invalid = value.length < 1

	return invalid ? `${propName} must have at least one value set.` : null
}

// https://stackoverflow.com/a/9284473/686490
const soRegForURLs = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
export const isWebsiteURL = (value: string, propName: string) =>
	soRegForURLs.test(value)
		? null
		: `${propName} must be a valid website address URL (including the http/https part).` // must be url
export const isBlankOrWebsiteURL = (value: string, propName: string) => {
	// Check if the value is blank
	if (value.trim() === '') {
		return null
	}

	// Use your existing URL regex to validate if the input is not blank
	return soRegForURLs.test(value)
		? null
		: `${propName} must be a valid website address URL (including the http/https part) or enter nothing.`
}

const regForEmail = /^\S+@\S+\.\S+$/
export const isEmail = (value: string, propName: string) =>
	regForEmail.test(value) ? null : `${propName} is not a valid email address`
export const isBlankOrEmail = (value: string, propName: string) => {
	if (value.trim() === '') {
		return null
	}
	return regForEmail.test(value)
		? null
		: `${propName} is not a valid email address`
}

// const youtubeRegForURLs = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)$/i
// const youtubeRegForURLs = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)(\?.*)?$/i
const youtubeRegForURLs = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)(&.*)?$/i

// https://www.youtube.com/watch?v=wX7VTcs1OPE&t=7s

export const isYouTubeURL = (value: string, propName: string) =>
	youtubeRegForURLs.test(value)
		? null
		: `${propName} must be a valid YouTube URL.` // must be a YouTube URL
export const isBlankOrYouTubeURL = (value: string, propName: string) => {
	if (value.trim() === '') {
		return null
	}
	return youtubeRegForURLs.test(value)
		? null
		: `${propName} must be a valid YouTube URL.`
}

/*
eg: https://www.youtube.com/@climbingthesevensummits
*/
const youtubeChannelRegForURLs = /^(https?:\/\/)?(www\.)?youtube\.com\/(c\/|channel\/|user\/|@)?([a-zA-Z0-9_-]+)(\?.*)?$/i

export const isBlankOrYouTubeChannel = (value: string, propName: string) => {
	if (value.trim() === '') {
		return null
	}
	return youtubeChannelRegForURLs.test(value)
		? null
		: `${propName} must be a valid YouTube Channel.`
}

// const tripAdvisorRegForURLs = /^https:\/\/www\.tripadvisor\.com\/Attraction_Review-g\d+-d\d+-Reviews-[\w_]+(?:-[\w_]+)*-\w+_\w+_\w+\.html$/i
// const tripAdvisorRegForURLs = /^https:\/\/www\.tripadvisor\.com\/Attraction_Review-g\d+-d\d+-Reviews-[\w_]+(?:-[\w_]+)*-(?:\w+)(?:_\w+)*\.html$/i
const tripAdvisorRegForURLs = /^https:\/\/www\.tripadvisor\.[a-z]{2,}(\.[a-z]{2,})?\/Attraction_Review-g\d+-d\d+-Reviews-[\w_]+(?:-[\w_]+)*-(?:\w+)(?:_\w+)*\.html$/i

export const isTripAdvisorURL = (value: string, propName: string) =>
	tripAdvisorRegForURLs.test(value)
		? null
		: `${propName} must be a valid TripAdvisor review page URL.` // must be a TripAdvisor URL
export const isBlankOrTripAdvisorURL = (value: string, propName: string) => {
	if (value.trim() === '') {
		return null
	}
	return tripAdvisorRegForURLs.test(value)
		? null
		: `${propName} must be a valid TripAdvisor review page URL.`
}

export const isValidWhatsAppNumber = (value: string, propName: string) => {
	const onlyNumbersAndSpacesReg = /^[\d\s]+$/
	return onlyNumbersAndSpacesReg.test(value)
		? null
		: `${propName} must contain only numbers and spaces.`
}

type GeoJSONFeature = {
	geometry: {
		type: string
		coordinates: [number, number] | [number, number][]
	}
	properties: {
		title: string
		type: Types.App.Map.RouteLineType | Types.App.Map.IconType
	}
}

export const parseGeoJSONToRoute = (route: string): Types.Trek.Route => {
	const obj: { type: string; features: GeoJSONFeature[] } = JSON.parse(route)
	return {
		locations: obj.features
			.filter((feature) => feature?.geometry?.type === 'Point')
			.map((location) => ({
				name: location.properties.title,
				longitude: location.geometry.coordinates[0] as number,
				latitude: location.geometry.coordinates[1] as number,
				type: location.properties.type as Types.App.Map.IconType,
			})),
		routeLines: obj.features
			.filter((feature) => feature?.geometry?.type === 'LineString')
			.map((location) => ({
				type: location.properties.type,
				linePoints: location.geometry.coordinates as [number, number][],
			})),
	}
}

export const isValidGEOJSONRoute = (route: string) => {
	// can I parse it to json at all
	let parsedGeoRoute: Types.Trek.Route | undefined = undefined
	try {
		parsedGeoRoute = parseGeoJSONToRoute(route)
	} catch (e) {
		return 'Invalid JSON entered'
	}

	// are all the markers etc of the right type
	for (let i = 0; i < parsedGeoRoute.locations.length; i++) {
		const location = parsedGeoRoute.locations[i]

		const invalidType = !((Types.App.Map
			.PossibleIconTypes as unknown) as string[]).includes(
			(location.type as unknown) as string,
		)

		if (invalidType) {
			// invalid location type
			return `${location.type} is an invalid marker type`
		}
	}

	return null
}

export const isValidJSON = (value: string, key: string) => {
	try {
		JSON.parse(value)
		return null
	} catch (e) {
		return key + ' is not valid JSON'
	}
}
