import { createContext, useContext, useEffect, useState } from 'react'

export const IsSsrMobileContext = createContext(false)
/*
https://javascript.plainenglish.io/the-right-way-to-detect-mobile-breakpoints-in-nextjs-301ccb1976bd
*/
export const useIsMobile = () => {
	const isSsrMobile = useContext(IsSsrMobileContext)
	const { width: windowWidth } = useWindowSize()
	const isBrowserMobile = !!windowWidth && windowWidth < 800

	return isSsrMobile || isBrowserMobile
}

export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState<{
		width?: number
		height?: number
	}>({
		width: typeof window !== 'undefined' ? window.innerWidth : undefined,
		height: typeof window !== 'undefined' ? window.innerHeight : undefined,
	})

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}

		// todo: maybe debounce this for better performance
		window.addEventListener('resize', handleResize)

		// Call handler right away so state gets updated with initial window size
		handleResize()

		// Don't forget to remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return windowSize
}
