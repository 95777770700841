import React from 'react';
import { Box, Burger, Button, Center, Divider, Drawer, Group, HoverCard, Image, List, rem, ScrollArea, SimpleGrid, Text } from '@mantine/core';
import Link from 'next/link';
import { useDisclosure } from '@mantine/hooks';
import { ChevronDown } from 'tabler-icons-react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import * as Redux from 'react-redux';
import { useIsMobile } from '../lib/useIsMobile';
import { VersionContext } from '../contexts/VersionContext';
import * as Selectors from '../redux/selectors';
import * as Actions from 'redux/actions';
import AccountModal from './AccountModal';
import CountryBadge from './CountryBadge';
import { sluggify } from '../lib/helper';
import * as Consts from 'consts';
const BrandTitle = () => {
  const isMobile = useIsMobile();
  const version = React.useContext(VersionContext)?.version;
  return <Link href="/" legacyBehavior data-sentry-element="Link" data-sentry-component="BrandTitle" data-sentry-source-file="Header.tsx">
			<a className="brand-title">
				<Image src="/mountains-sun.svg" alt="guided Trekking icon" width={28} height={28} radius={'lg'} data-sentry-element="Image" data-sentry-source-file="Header.tsx" />
				<span className="brand-header-text">
					{isMobile && false ? version === 'TREKKING' ? 'GT' : 'GP' : `Guided ${version === 'TREKKING' ? 'Trekking' : 'Peaks'}`}
				</span>
			</a>
		</Link>;
};
const HeaderMegaMenu = () => {
  const router = useRouter();
  const [drawerOpened, {
    toggle: toggleDrawer,
    close: closeDrawer
  }] = useDisclosure(false);
  const dispatch = Redux.useDispatch();
  React.useEffect(() => {
    // Check if the URL has the specific hash
    if (router.asPath.includes('#open-login-guide')) {
      dispatch(Actions.openLogin());
    }
  }, [router.asPath, dispatch]);
  const authedUser = useSelector(Selectors.authedUser);
  const isAdmin = useSelector(Selectors.isUserAdmin);
  const version = React.useContext(VersionContext).version;
  const isPeaks = version === 'PEAKS';
  const mobileLinks = Consts.regions.map((region, idxRegion) => <div key={idxRegion}>
			{region.map((continent, idxContinent) => <div key={idxContinent}>
					<Link href={`/climbing/${sluggify(continent.continent)}`}>
						<Text size="sm" fw={600} m="sm" className={classNames({
          'header-link': true,
          'active-link': ['/countries', `/[routeType]/[continent]`, `/[routeType]/[continent]/[countries]`].some(route => {
            return router.pathname === route;
          })
        })}>
							{continent.continent}
						</Text>
					</Link>
					<List listStyleType="none">
						{continent.countries.map(country => <List.Item key={country.label}>
								<Link href={country.href} className="header-link">
									<Text size="sm" m="xs" fw={500}>
										<CountryBadge isoCode={country.isoCode} />
									</Text>
								</Link>
							</List.Item>)}
					</List>
				</div>)}
		</div>);
  return <div id="header2" data-sentry-component="HeaderMegaMenu" data-sentry-source-file="Header.tsx">
			<header>
				<Group style={{
        justifyContent: 'space-between'
      }} h="100%" id="brand" data-sentry-element="Group" data-sentry-source-file="Header.tsx">
					<div style={{
          padding: '8px'
        }}>
						<BrandTitle data-sentry-element="BrandTitle" data-sentry-source-file="Header.tsx" />
					</div>

					<div className="mobile-hide">
						<Group data-sentry-element="Group" data-sentry-source-file="Header.tsx">
							<Link href="/expeditions" legacyBehavior data-sentry-element="Link" data-sentry-source-file="Header.tsx">
								<a className={'header-link'}>
									<Text size="sm" m="sm" fw={500}
                // className={classNames({
                // 	'active-link': router.pathname === '/expeditions',
                // })}
                className={classNames({
                  'header-link': true,
                  'active-link': ['/expeditions', `/[routeType]/[continent]/[countries]/[slug]`].some(route => {
                    return router.pathname === route;
                  })
                })} data-sentry-element="Text" data-sentry-source-file="Header.tsx">
										{isPeaks ? 'Expeditions' : 'Treks'}
									</Text>
								</a>
							</Link>

							{isPeaks && <>
									<Link href="/guides" legacyBehavior>
										<a className={'header-link'}>
											<Text size="sm" m="sm" fw={500} className={classNames({
                    'header-link': true,
                    'active-link': router.pathname.startsWith('/guides')
                  })}>
												Guides
											</Text>
										</a>
									</Link>

									<Link href="/articles" legacyBehavior>
										<a className={'header-link'}>
											<Text size="sm" m="sm" fw={500} className={classNames({
                    'header-link': true,
                    'active-link': router.pathname.startsWith('/articles')
                  })}>
												Articles
											</Text>
										</a>
									</Link>

									<HoverCard position="bottom" radius="md" shadow="md" withinPortal>
										<HoverCard.Target>
											<Link href="/countries" className={'header-link'}>
												<Center inline>
													<Box component="span" mr={5}>
														<Text size="sm" m="sm" fw={500} className={classNames({
                          'header-link': true,
                          'active-link': router.pathname.startsWith('/countries') || router.pathname === `/[routeType]/[continent]/[countries]` || router.pathname === `/[routeType]/[continent]`
                        })}>
															Countries
														</Text>
													</Box>
													<ChevronDown style={{
                        width: rem(16),
                        height: rem(16)
                      }} />
												</Center>
											</Link>
										</HoverCard.Target>

										<HoverCard.Dropdown style={{
                  overflow: 'hidden',
                  width: '600px'
                }}>
											<SimpleGrid cols={3} spacing={0}>
												{mobileLinks}
											</SimpleGrid>
										</HoverCard.Dropdown>
									</HoverCard>

									<Link href="/about" legacyBehavior style={{
                margin: '16px'
              }}>
										<a className={'header-link'}>
											<Text size="sm" m="sm" fw={500} className={classNames({
                    'header-link': true,
                    'active-link': router.pathname === '/about'
                  })}>
												About
											</Text>
										</a>
									</Link>
								</>}
						</Group>
					</div>

					<div className="mobile-hide">
						<Group p="xs" data-sentry-element="Group" data-sentry-source-file="Header.tsx">
							{isAdmin && <Link href="/admin" passHref>
									<Button title={`logged in as ${authedUser?.id} (${authedUser?.role})`}>
										Admin
									</Button>
								</Link>}
							<AccountModal data-sentry-element="AccountModal" data-sentry-source-file="Header.tsx" />
						</Group>
					</div>

					<Burger opened={drawerOpened} onClick={toggleDrawer} className="mobile-show" mr="sm" data-sentry-element="Burger" data-sentry-source-file="Header.tsx" />
				</Group>
			</header>

			<Drawer opened={drawerOpened} onClose={closeDrawer} size="100%" padding="md" title={<BrandTitle />} className="mobile-show" zIndex={10000} data-sentry-element="Drawer" data-sentry-source-file="Header.tsx">
				<>
					<Divider data-sentry-element="Divider" data-sentry-source-file="Header.tsx" />
					<ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md" p="md" data-sentry-element="ScrollArea" data-sentry-source-file="Header.tsx">
						<Link href="/expeditions" legacyBehavior style={{
            margin: '16px'
          }} data-sentry-element="Link" data-sentry-source-file="Header.tsx">
							<a className={'header-link'}>
								<Text size="sm" fw={500} data-sentry-element="Text" data-sentry-source-file="Header.tsx">
									{isPeaks ? 'Explore peaks' : 'Explore treks'}
								</Text>
							</a>
						</Link>

						{isPeaks && <>
								<Link href="/guides" legacyBehavior style={{
              margin: '16px'
            }}>
									<a className={'header-link'}>
										<Text size="sm" fw={500}>
											Guides
										</Text>
									</a>
								</Link>
								<Link href="/articles" legacyBehavior style={{
              margin: '16px'
            }}>
									<a className={'header-link'}>
										<Text size="sm" fw={500}>
											Articles
										</Text>
									</a>
								</Link>
								<List style={{
              margin: '16px',
              marginLeft: 0
            }}>
									<Text size="sm" fw={500}>
										<Link href="/places" className={'header-link'} legacyBehavior style={{
                  margin: '16px',
                  marginLeft: 0
                }}>
											Places:
										</Link>
									</Text>
									{mobileLinks}
								</List>
								<Link href="/about" className={'header-link'} legacyBehavior style={{
              margin: '16px',
              marginLeft: 0
            }}>
									<a className={'header-link'}>
										<Text size="sm" fw={500}>
											About
										</Text>
									</a>
								</Link>
								<Divider my="sm" />
							</>}
						<div className="header-mobile-button-group">
							<Group grow pb="xl" mt="xl" data-sentry-element="Group" data-sentry-source-file="Header.tsx">
								{isAdmin && <Link href="/admin" passHref>
										<Button fullWidth title={`logged in as ${authedUser?.id} (${authedUser?.role})`} className="header-mobile-button-group-button">
											Admin
										</Button>
									</Link>}
								<AccountModal data-sentry-element="AccountModal" data-sentry-source-file="Header.tsx" />
							</Group>
						</div>
					</ScrollArea>
				</>
			</Drawer>
		</div>;
};
export default HeaderMegaMenu;